<template>
  <div class="error-info">
    <p class="error-title">{{ this.errorMessage }}</p>
    <p v-if="this.errorDesc" class="error-desc"> {{ this.errorDesc }} </p>
  </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import {
  HTTP_CODE_INTERNAL_SERVER_ERROR,
  HTTP_CODE_NOT_FOUND,
  PAGE_TITLE_NORMAL
} from '@constants'
import Vue from 'vue'

export default {
  name: 'ErrorLayout',
  components: {
  },
  props: {
    errorStatus: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null,
      errorDesc: null
    }
  },
  mounted() {
    this.setError()
  },
  created() {
    // Set title for page
    let pageTitle = PAGE_TITLE_NORMAL;
    Vue.nextTick(() => {
      document.title = pageTitle;
    });
  },
  methods: {
    setError: function() {
      switch (this.errorStatus) {
        case HTTP_CODE_NOT_FOUND:
          this.errorMessage = '404'
          this.errorDesc = 'Page is not found'
          break
        case HTTP_CODE_INTERNAL_SERVER_ERROR:
          this.errorMessage = 'We`ll be back soon!'
          this.errorDesc = "Sorry for the inconcenience but we're performing some main tenance at the moment"
          break
      }
    }
  }
}
</script>
